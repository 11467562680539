import React from 'react'
import { Link } from 'theme-ui'
import Wintomato3 from '../../assets/Wintomato_653x81.png'
import Wintomato2 from '../../assets/Wintomato_900x300.png'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    }
  },
  responsive: {
    width: '100%',
    height: 'auto'
  }
}

const WintomatoBannerBig = () => (
  <Link
    variant='mute'
    target='_blank'
    title='Wintomato'
    href='https://wintomato.com/en/registration/22952'
    rel='noopener'
    sx={styles.link}
  >
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <img
      src={Wintomato3}
      style={styles.responsive}
      srcSet={`${Wintomato2} 900w, ${Wintomato3}`}
    />
  </Link>
)

export default WintomatoBannerBig
