import React from 'react'
import { Link } from 'theme-ui'
import Wintomato2 from '../../assets/Wintomato_320x440.png'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    }
  },
  responsive: {
    width: '100%',
    height: 'auto'
  }
}

const WintomatoBannerSquare = () => (
  <Link
    variant='mute'
    target='_blank'
    title='Wintomato'
    href='https://wintomato.com/en/registration/22952'
    rel='noopener'
    sx={styles.link}
  >
    <img src={Wintomato2} style={styles.responsive} />
  </Link>
)

export default WintomatoBannerSquare
